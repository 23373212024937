import React from "react"
import { graphql } from 'gatsby'
import he from 'he'

import Seo from "../components/seo"
import ShopArchive from '../components/shop-archive'

const ShopPage = ({ location, pageContext, data }) => {

  const title = pageContext.brand

  const products = data.allShopifyProduct.nodes

  return (
    <>
      <Seo title={he.decode('Shop')} bodyClass='shop' />
      <ShopArchive title={title} products={products} pageContext={pageContext} />
    </>
  )
}

export const pageQuery = graphql`
  query ($brand: String!) {
    allShopifyProduct(filter: {vendor: {eq:$brand}}) {
      nodes {
        description
        descriptionHtml
        handle
        featuredImage {
          originalSrc
          width
          height
        }
        title
        vendor
        tags
        variants {
          price
          priceNumber : price
        }
        metafields {
          key
          value
        }
      }
    }
  }
`


export default ShopPage
